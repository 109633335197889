import { stringify } from "qs"
import Axios from "axios"
import urls from "./urls"
import { getAuthInfo, saveAuthInfo, deleteAuthInfo } from "./helpers"
import { USER_TYPE } from "@/utils/constants"

const SSO_URL = process.env.VUE_APP_SSO_URL

const axios = Axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  withCredentials: true,
  paramsSerializer: params => {
    // Remove keys that are null
    function clean(obj) {
      for (const key in obj) {
        if (obj[key] === null) {
          delete obj[key]
        }
      }
    }
    clean(params)

    return stringify(params, { arrayFormat: "repeat" })
  },
})

// request interceptor to add the token
axios.interceptors.request.use(request => {
  const authInfo = getAuthInfo()

  if (authInfo) {
    request.headers["Authorization"] = `Bearer ${authInfo.access_token}`
  }

  request.headers["Content-Type"] = "application/json"

  return request
})

let tokenIsRefreshing = false

const interceptor = error => {
  error.serverFailure =
    error.response === undefined || error.response.status === 500

  if (error.response) {
    if (error.response.status === 401) {
      if (tokenIsRefreshing) {
        return Promise.reject(error)
      }

      tokenIsRefreshing = true
      refreshToken()

      // Reject the current request while the token refreshes
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
  return Promise.reject(error)
}

// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
function refreshToken() {
  Axios({
    method: "post",
    url: `${process.env.VUE_APP_BASEURL}${urls.auth.refreshToken}`,
    data: {
      refresh_token: getAuthInfo().refresh_token,
    },
  })
    .then(response => {
      const authInfo = {
        ...getAuthInfo(),
        access_token: response.data.data.access_token,
        refresh_token: response.data.data.refresh_token,
      }
      saveAuthInfo(authInfo)

      // If refresh token call succeeds then resolve all the calls in the queue with the new token
      // processQueue(null, response.data.data.access_token)
      // resolve(axios(originalRequest))
      window.location.reload()
    })
    .catch(error => {
      if (error.response) {
        // eslint-disable-next-line no-console
        console.log("> Refresh token expired. Logging out.")
        deleteAuthInfo()
        window.location.href = `${SSO_URL}/?platform=${USER_TYPE}&redirect-url=${window.location.origin}/?next=${window.location.pathname}`
      }
      // If refresh token call fails then reject all the calls in the queue
      // processQueue(err)
      // reject(err)
    })
    .finally(() => {
      tokenIsRefreshing = false
    })
}

axios.interceptors.response.use(undefined, interceptor)

export default axios
