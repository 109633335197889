<template>
  <v-app>
    <v-main>
      <div class="custom-toast-container">
        <Toast v-for="item in toast" :key="item.id" v-bind="item" />
      </div>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex"
import Toast from "@/components/shared/Toast"
export default {
  name: "App",
  components: { Toast },
  computed: {
    ...mapGetters(["toast"]),
  },
}
</script>
<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

html,
body {
  scroll-behavior: smooth;
  margin: 0 !important;
  padding: 0 !important;
}
p {
  margin: 0;
}

.theme--light.v-application {
  background-color: var(--v-secondary-base) !important;
}

.custom-toast-container {
  // Should be above all content on the DOM
  z-index: 203;
  position: absolute;
  top: 75px;
  right: 10px;
}

// Override default data table styles
.v-data-table {
  thead {
    background-color: var(--v-bg-base);
  }
  th {
    border-bottom: none !important;
    color: #959595 !important;
  }

  tr {
    $row-padding: 30px;

    & > td:first-child {
      padding-left: $row-padding !important;
    }
    /* & > td:last-child {
      padding-right: 0 !important;
    } */
    & > th:first-child {
      padding-left: $row-padding !important;
    }
    & > th:last-child {
      padding-right: 0 !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-data-table {
    tr {
      & > td:first-child {
        padding-left: 16px !important;
      }
      & > th:first-child {
        padding-left: 0px !important;
      }
    }

    & > .v-data-table__wrapper table tbody tr td {
      height: 56px !important;
    }
  }

  .v-data-table__mobile-row {
    padding-top: 16px !important;
    align-items: flex-start !important;
  }

  .v-data-table__wrapper {
    overflow: hidden !important;
  }

  .header-text {
    font-size: 20px !important;
  }
}
//Global styling used in each heading of view pages
.header-fields .v-input__control .v-input__slot {
  padding: 12px 30px !important;
}

.v-application--is-ltr .header-fields .v-input__slot .v-input__prepend-inner {
  margin-right: 10px !important;
}

.v-application p {
  margin-bottom: 0;
}
// Analytics screen
.button-start-checking {
  font-size: 16px !important;
}
.header-text {
  font-size: 36px !important;
}

.show-plagiarism-highlights {
  .suraasa-plagiarism-detected:not(.suraasa-plagiarism-detected
      > .suraasa-plagiarism-detected) {
    background-color: #f1cdcd !important;
    color: #4a2e3c !important;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    pointer-events: auto !important;
    padding-left: 24px;
    display: block;

    &::before {
      content: attr(data-before);
      position: absolute;
      height: 20px;
      left: 0;
      width: 20px;
      border-radius: 50%;
      color: #4a1616;
      text-align: center;
      background-color: #e18a8a;
    }
  }
}

.suraasa-plagiarism-original-source,
.suraasa-plagiarism-original-source *[style] {
  background-color: #faecc1 !important;
  color: #514c2e !important;
  padding: 4px;
  border-radius: 4px;
}
</style>
